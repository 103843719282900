var site = site || {};
site.productView = site.productView || {};

site.productView.mpp = {
  init : function(productsData) {
    var $mppProducts = $('.product-brief');
    var mppRows = _.groupBy($mppProducts, function(element, index){
      return Math.floor(index/2); //2 in a row
    });
    mppRows = _.toArray(mppRows);

    //even out row height
    setTimeout(function(){ // timeout to let fonts render - @font-face FOUT
      site.productView.mpp.rowHeight(mppRows);
      $('.mpp__product-grid').animate({opacity: 1},100);
    },120);

    for(var i=0; i<mppRows.length; i++){
      var $mppRow = $(mppRows[i]);
      $mppRow.data('row-index', i);
      $mppRow.eq(1).addClass('product-brief--second');
      $quickshopContainer = '<li class="product-brief__quickshop-container js-quickshop-container" data-row-index="' + i + '"></li>';
      $mppRow.eq(1).after($quickshopContainer);
    }
    // if last row is odd add quickshop li
    if( !_.isUndefined(mppRows) && mppRows.length != 0 ){
      var lastMppRow = mppRows.length-1;
      if(mppRows[mppRows.length-1].length == 1){
        $('.product-brief:last-child').after($quickshopContainer);
      }
    }
    // initialize individual product views
    for (var i=0; i<productsData.length; i++) {
      var item = site.productView.mobileMppItem(productsData[i]);
    }

    //update row height on resize
    $( window ).resize(function() {
      site.productView.mpp.rowHeight(mppRows);
    });

    // update row height after inv status is updated
    $(".js-inv-status-list").unbind('inventory_status_updated');
    $(".js-inv-status-list").on("inventory_status_updated", function(e) {
      var $parentContainer = $(this).parents(".product-brief");
      var rowToResize = _.find(mppRows, function(mppRow) {
        return _.contains(mppRow, $parentContainer[0]);
      })
      site.productView.mpp.rowHeight([rowToResize]);
    });
  }, // end init
  rowHeight: function(mppRows) {
    _.each(mppRows, function(mppRow) {
      _.each(mppRow, function(productContainer) {
        $(productContainer).removeAttr('style');
      });
    });
    //misc flag - extra padding
    $(mppRows).each(function(index, row) {
      var hasFlag = 0;
      var flagHeight = '19px';
      $(row).each(function(index, product) {
        if($(product).find('.product-brief__misc-flag').length){
          hasFlag++;
          flagHeight = $(product).find('.product-brief__misc-flag').outerHeight(true);
        }
      });
      $(row).each(function(index, product) {
        if(hasFlag && $(product).find('.product-brief__misc-flag').length == 0){
          var $spacer = $('<div class="product-brief__misc-flag-spacer">');
          $spacer.height(flagHeight)
          $(product).find('.product-brief__header').before($spacer);
        }
      });
    });

    for(var x in mppRows){
      var mppRow = mppRows[x];
      var maxProductHeight = Math.max.apply(null,$(mppRow).map(function(){
        return $(this).outerHeight();
      }).get());
      $(mppRow).outerHeight(maxProductHeight);
    }
  }
};

site.productView.mobileMppRowHeights = function() {
  var $mppProducts = $('.product-brief');
  var mppRows = _.groupBy($mppProducts, function(element, index){
    return Math.floor(index/2); //2 in a row
  });
  mppRows = _.toArray(mppRows);
  $mppProducts.removeAttr('style');
  site.productView.mpp.rowHeight(mppRows);
}

site.productView.mobileMppItem = function(productData){
  var that = {
    productData: productData
  };
  var p = that.productData;
  var $prodContainer = $(".product-brief[data-product-id='" + p.PRODUCT_ID + "']");
  $('.product-brief__image', $prodContainer).css('background-image','url(' + p.skus[0].MEDIUM_IMAGE + ')');
  var pos = $prodContainer.hasClass('product-brief--second') ? 1 : 0;
  var $qsContainer = $(".js-quickshop-container[data-row-index='" + $prodContainer.data('row-index') + "']");
  var qs = site.productView.inlineQuickshopMobile({
    productData: p,
    position: pos,
    $productContainer: $prodContainer,
    $quickshopContainer: $qsContainer
  });
  var $showButton = $(".js-quickshop-show[data-product-id='" + p.PRODUCT_ID + "']");
  var $hideButton = $(".js-quickshop-hide[data-product-id='" + p.PRODUCT_ID + "']");
  $showButton.unbind('click');
  $showButton.on("click", function(evt) {
    console.log('showing');
    evt.preventDefault();
    qs.launch();
    $(".js-quickshop-hide").addClass('hidden'); // hide all hide buttons
    $(".js-quickshop-show").removeClass('hidden'); // show all show buttons
    $hideButton.removeClass('hidden');
    $showButton.addClass('hidden');
  });
  $hideButton.unbind('click');
  $hideButton.on("click", function(evt) {
    evt.preventDefault();
    qs.close();
    $hideButton.addClass('hidden');
    $showButton.removeClass('hidden');
  });

  var $prodImg = $(".product-brief__image[data-product-id='" + p.PRODUCT_ID + "']");
  $prodImg.unbind('sku:select');
  $prodImg.on('sku:select', function(e, skuData) {
    var skuImg = $.isArray(skuData.MEDIUM_IMAGE) ? skuData.MEDIUM_IMAGE[0] : skuData.MEDIUM_IMAGE;
    $(this).css('background-image','url(' + skuImg + ')');
    e.stopPropagation;
  });
  return that;
};

site.productView.mobileMppRebuild = function(productData){
    var $mppProducts = $('.product-brief');
    $mppProducts.removeClass('product-brief--second');
    //remove quickshop
    var $quickshop = $('.js-quickshop-container');
    $quickshop.remove();
    site.productView.mpp.init(productData);
};
